.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.information{
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

}

.information input{
 width: 300px;
 height: 50px;
 font-size: 20px;
 padding: 10px;
 margin: 5px;
}

.information button{
  width: 320px;
  height: 50px;
  margin-top: 15px;
}

.information button:hover{
  cursor: pointer;
}

.employees {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.employee {
  width: 700px;
  height: 300px;
  background-color: lightgray;
  border: 1px solid black;
  margin: 20px;
  display: flex;
  padding: 20px;
  font-family: 'Courier New', Courier, monospace;


}

.employee h3{
  margin: 20px;

}

.buttons {
  padding-left: 200px;
}

.introd{
  padding-left: 195px;
}




